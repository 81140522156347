import React from 'react';
import { FaSearch, FaUserCheck, FaPaperPlane, FaCalendarCheck, FaChartLine } from 'react-icons/fa';

const Step = ({ icon, title, description }) => (
  <div className="flex flex-col items-center text-center">
    <div className="text-4xl text-purple-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const HowItWorks = () => {
  const steps = [
    {
      icon: <FaSearch />,
      title: "Identify Prospects",
      description: "Neo scours the internet to find promising leads tailored to your business."
    },
    {
      icon: <FaUserCheck />,
      title: "Research and Personalize",
      description: "AI analyzes prospects' needs and crafts personalized messages."
    },
    {
      icon: <FaPaperPlane />,
      title: "Automated Outreach",
      description: "Neo sends tailored emails and manages follow-ups automatically."
    },
    {
      icon: <FaCalendarCheck />,
      title: "Schedule Meetings",
      description: "When prospects show interest, Neo books meetings directly into your calendar."
    },
    {
      icon: <FaChartLine />,
      title: "Optimize Performance",
      description: "Neo continuously learns and improves, refining its approach for better results."
    }
  ];

  return (
    <section id="how-it-works" className="py-20 bg-gray-50">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-12">How Neo Works for You</h2>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          {steps.map((step, index) => (
            <Step key={index} {...step} />
          ))}
        </div>
        <div className="mt-12 flex justify-center">
          <svg className="w-full max-w-3xl" height="50" viewBox="0 0 800 50">
            <line x1="0" y1="25" x2="800" y2="25" stroke="#8B5CF6" strokeWidth="2" />
            <circle cx="0" cy="25" r="8" fill="#8B5CF6" />
            <circle cx="200" cy="25" r="8" fill="#8B5CF6" />
            <circle cx="400" cy="25" r="8" fill="#8B5CF6" />
            <circle cx="600" cy="25" r="8" fill="#8B5CF6" />
            <circle cx="800" cy="25" r="8" fill="#8B5CF6" />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
