import React, { useState } from 'react';
import { FaClock, FaUsers, FaEnvelope, FaUserTie, FaRedo, FaSearch, FaBullseye, FaUserCheck, FaPaperPlane, FaCalendarCheck, FaChartLine } from 'react-icons/fa';

const TimelineItem = ({ title, content, isActive, onClick }) => (
  <div className={`mb-8 flex justify-between items-center w-full ${isActive ? 'opacity-100' : 'opacity-50'}`}>
    <div className="order-1 w-5/12"></div>
    <div className="z-20 flex items-center order-1 bg-neo-purple shadow-xl w-8 h-8 rounded-full">
      <h1 className="mx-auto font-semibold text-lg text-white">{title[0]}</h1>
    </div>
    <div className="order-1 bg-white rounded-lg shadow-xl w-5/12 px-6 py-4 cursor-pointer" onClick={onClick}>
      <h3 className="mb-3 font-bold text-neo-purple text-xl">{title}</h3>
      {isActive && (
        <div className="text-sm leading-snug tracking-wide text-gray-700 text-opacity-100">
          {content.map((item, index) => (
            <div key={index} className="flex items-start mb-2">
              <div className="mr-3 mt-1">{item.icon}</div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

const CaseStudy = () => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      title: "Before Neo",
      content: [
        { icon: <FaClock className="text-red-500" />, text: "4 hours spent researching a single lead" },
        { icon: <FaUsers className="text-red-500" />, text: "Limited outreach to 2-3 prospects per day" },
        { icon: <FaEnvelope className="text-red-500" />, text: "No email campaigns. Everything is manual" },
        { icon: <FaUserTie className="text-red-500" />, text: "Difficulty identifying decision-makers" },
        { icon: <FaRedo className="text-red-500" />, text: "Inconsistent follow-ups" },
      ],
    },
    {
      title: "With Neo",
      content: [
        { icon: <FaClock className="text-green-500" />, text: "Lead research time cut to minutes" },
        { icon: <FaUsers className="text-green-500" />, text: "50+ qualified prospects reached daily" },
        { icon: <FaEnvelope className="text-green-500" />, text: "Hyper-personalized outreach campaigns" },
        { icon: <FaUserTie className="text-green-500" />, text: "Key decision-makers accurately identified" },
        { icon: <FaRedo className="text-green-500" />, text: "Automated, timely follow-ups" },
      ],
    },
    {
      title: "Neo's Impact",
      content: [
        { icon: <FaSearch className="text-blue-500" />, text: "Smart Prospecting: Neo identified Luxe Wellness Center in Chicago as a top prospect for our client's high-end massage chairs." },
        { icon: <FaBullseye className="text-blue-500" />, text: "Decision Maker Identification: Neo pinpointed Dr. Elizabeth Chen, Luxe Wellness Center's CEO, as the key decision-maker." },
        { icon: <FaUserCheck className="text-blue-500" />, text: "Personalized Outreach: A tailored email was crafted, highlighting how our client's chairs complement Luxe Wellness Center's holistic approach." },
        { icon: <FaPaperPlane className="text-blue-500" />, text: "Automated Follow-up: Neo managed timely follow-ups, maintaining engagement without manual intervention." },
        { icon: <FaCalendarCheck className="text-blue-500" />, text: "Meeting Scheduling: Neo successfully scheduled a demo, putting our client on track to partner with this prestigious wellness center." },
      ],
    },
    {
      title: "Key Result",
      content: [
        { icon: <FaChartLine className="text-purple-500" />, text: "Our client now reaches 50 qualified prospects daily, achieving 10x the performance of their previous sales team. This has transformed their sales process and exponentially increased their potential for high-end massage chair sales." },
      ],
    }
  ];

  return (
    <section id="case-study" className="py-20 bg-gradient-to-br from-gray-100 to-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12 text-neo-purple">Neo in Action: B&Y USA's Success Story</h2>
        <div className="relative wrap overflow-hidden p-10 h-full">
          <div className="border-2-2 absolute border-opacity-20 border-neo-purple h-full border" style={{left: '50%'}}></div>
          {steps.map((step, index) => (
            <TimelineItem
              key={index}
              title={step.title}
              content={step.content}
              isActive={activeStep === index}
              onClick={() => setActiveStep(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudy;
