import React from 'react';
import Header from './components/Header.jsx';
import Hero from './components/Hero.jsx';
import Benefits from './components/Benefits.jsx';
import HowItWorks from './components/HowItWorks.jsx';
import CaseStudy from './components/CaseStudy.jsx';
import Features from './components/Features.jsx';
import ValueProposition from './components/ValueProposition.jsx';
import Testimonials from './components/Testimonials.jsx';
import Contact from './components/Contact.jsx';
import Footer from './components/Footer.jsx';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Benefits />
      <HowItWorks />
      <CaseStudy />
      <Features />
      <ValueProposition />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
