import React from 'react';

const Header = () => {
  return (
    <header className="bg-white shadow-sm">
      <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
        <div className="flex items-center">
          <img 
            src="/image.png" 
            alt="Logo" 
            className="h-8 w-auto filter invert" 
            style={{ filter: 'invert(1)' }}
          />
        </div>
        <div className="hidden md:flex items-center space-x-6">
          <a href="#benefits" className="text-gray-600 hover:text-gray-900">Benefits</a>
          <a href="#how-it-works" className="text-gray-600 hover:text-gray-900">How It Works</a>
          <a href="#case-study" className="text-gray-600 hover:text-gray-900">Case Study</a>
          <a href="#features" className="text-gray-600 hover:text-gray-900">Features</a>
          <a href="#testimonials" className="text-gray-600 hover:text-gray-900">Testimonials</a>
        </div>
        <div>
          <a href="#contact" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300">Get Started</a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
