import Airtable from 'airtable';

   const base = new Airtable({
     endpointUrl: 'https://api.airtable.com',
     apiKey: 'patY2FYgNKXJiTMbs.aa580322e3508f62500b06aa0bb4955215ff8400d87e15769d6256e0712638d5'
   }).base('appoDQwbHno0n9eMN');


export const addContact = async (name, email, message) => {
  try {
    const result = await base('NeoBDRClients').create([
      {
        fields: {
          Name: name,
          Email: email,
          Message: message
        }
      }
    ]);
    console.log('Added record to Airtable:', result);
    return result;
  } catch (error) {
    console.error('Error adding record to Airtable:', error);
    throw error;
  }
};