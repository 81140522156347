import React from 'react';

const FeatureCard = ({ icon, title, description, color }) => (
  <div className={`bg-gradient-to-br ${color} p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl text-white`}>
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-100">{description}</p>
  </div>
);

const Features = () => {
  const features = [
    {
      icon: "🔍",
      title: "Smart Prospecting",
      description: "Access 100M+ contacts with advanced filters to find your ideal customers.",
      color: "from-neo-purple to-neo-pink"
    },
    {
      icon: "✍️",
      title: "Personalized Outreach",
      description: "Craft tailored messages that resonate with each prospect's unique needs.",
      color: "from-neo-pink to-neo-red"
    },
    {
      icon: "🔄",
      title: "Automated Follow-ups",
      description: "Manage entire email sequences with timely, automated follow-ups.",
      color: "from-neo-blue to-neo-teal"
    },
    {
      icon: "📊",
      title: "Intuitive Dashboard",
      description: "Get a bird's-eye view of your sales pipeline with real-time metrics and insights.",
      color: "from-neo-teal to-neo-yellow"
    }
  ];

  return (
    <section id="features" className="py-20 bg-gradient-to-b from-gray-100 to-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12 text-neo-blue">Supercharge Your Sales with These Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
