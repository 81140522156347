import React from 'react';

const Hero = () => {
  return (
    <section className="bg-gradient-to-r from-neo-purple via-neo-pink to-neo-red text-white py-24 relative">
      <div className="absolute inset-0 bg-opacity-20 bg-black" style={{
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        backgroundSize: '60px 60px'
      }}></div>
      <div className="container mx-auto px-6 relative z-10">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-10 md:mb-0">
            <h1 className="text-4xl md:text-6xl font-bold mb-6 animate-bounce-slow">Supercharge Your Sales with VEnable AI</h1>
            <p className="text-xl mb-8">Neo, your AI sales assistant, revolutionizes your outreach while you focus on closing deals. Transform your sales efficiency in just 2 minutes.</p>
            <p className="text-xl mb-8 font-semibold">Neo takes care of the entire end-to-end outbound process, from prospecting to booking meetings.</p>
            <div className="flex space-x-4">
              <a href="#contact" className="bg-white text-neo-purple px-8 py-4 rounded-full font-semibold hover:bg-neo-yellow hover:text-white transition duration-300 transform hover:scale-105">Get Started</a>
              <a href="#how-it-works" className="border-2 border-white text-white px-8 py-4 rounded-full font-semibold hover:bg-white hover:text-neo-purple transition duration-300 transform hover:scale-105">See How It Works</a>
            </div>
          </div>
          <div className="md:w-1/2">
            <img src="/robot-working.png" alt="Neo BDR in action" className="rounded-lg shadow-2xl transform hover:rotate-2 transition duration-300" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
