import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const BenefitCard = ({ icon, title, description, color }) => (
  <div className={`bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl border-t-4 ${color}`}>
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Benefits = () => {
  const benefits = [
    {
      icon: "⏱️",
      title: "Save Time",
      description: "Cut lead research time from hours to minutes, freeing you to focus on closing deals.",
      color: "border-neo-purple"
    },
    {
      icon: "🎯",
      title: "10x Efficiency Boost",
      description: "Outperform human sales specialists by 10x, reaching 50+ qualified prospects daily with personalized outreach.",
      color: "border-neo-pink"
    },
    {
      icon: "💼",
      title: "Grow Your Business",
      description: "Expand your reach and increase sales without expanding your team or costs.",
      color: "border-neo-blue"
    },
    {
      icon: "🔍",
      title: "Find Ideal Customers",
      description: "Access 100M+ contacts with 50+ filters to pinpoint your perfect prospects.",
      color: "border-neo-teal"
    },
    {
      icon: <FaCalendarAlt className="text-neo-yellow" />,
      title: "Relax While Neo Works",
      description: "Sit back as Neo schedules demos directly into your calendar, filling up your pipeline effortlessly.",
      color: "border-neo-yellow"
    }
  ];

  return (
    <section id="benefits" className="py-20 bg-gradient-to-b from-white to-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12 text-neo-purple">How Neo Supercharges Your Business</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
