import React from 'react';

const TestimonialCard = ({ quote, author, role, company }) => (
  <div className="bg-gradient-to-br from-purple-100 to-pink-100 p-6 rounded-lg shadow-md">
    <p className="text-gray-800 mb-4 italic">"{quote}"</p>
    <div>
      <p className="font-semibold text-purple-700">{author}</p>
      <p className="text-sm text-gray-600">{role}, {company}</p>
    </div>
  </div>
);

const Testimonials = () => {
  const testimonials = [
    {
      quote: "NeoBDR has transformed our sales process. We're closing deals 30% faster and our team is more productive than ever.",
      author: "Sarah Johnson",
      role: "Sales Director",
      company: "TechCorp Inc."
    },
    {
      quote: "The AI-powered lead scoring has been a game-changer for us. We're focusing on the right prospects and seeing amazing results.",
      author: "Michael Chen",
      role: "VP of Sales",
      company: "GrowthMasters"
    },
    {
      quote: "NeoBDR's personalized messaging capabilities have significantly improved our engagement rates. It's like having a sales expert for each prospect.",
      author: "Emily Rodriguez",
      role: "Head of Business Development",
      company: "InnovateNow"
    }
  ];

  return (
    <section id="testimonials" className="py-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-12">What Our Customers Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
