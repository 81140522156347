import React from 'react';
import { FaMoneyBillWave, FaClock, FaChartLine, FaLightbulb } from 'react-icons/fa';

const ValueCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105">
    <div className="text-4xl mb-4 text-neo-purple">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const ValueProposition = () => {
  const values = [
    {
      icon: <FaMoneyBillWave />,
      title: "Cost Efficiency",
      description: "Reduce your sales costs by up to 70% while increasing output tenfold."
    },
    {
      icon: <FaClock />,
      title: "Time Savings",
      description: "Cut lead research time from hours to minutes, freeing up 80% of your day."
    },
    {
      icon: <FaChartLine />,
      title: "Operational Gains",
      description: "Boost your outreach capacity by 500%, reaching more qualified prospects daily."
    },
    {
      icon: <FaLightbulb />,
      title: "Clarity in Decision Making",
      description: "Eliminate guesswork with AI-driven insights and data-backed strategies."
    }
  ];

  return (
    <section id="value-proposition" className="py-20 bg-gradient-to-b from-gray-100 to-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12 text-neo-blue">The Neo BDR Advantage</h2>
        <div className="flex flex-col lg:flex-row items-center justify-between mb-12">
          <img src="/shaking-hand.png" alt="AI and Human collaboration" className="w-full lg:w-1/3 mb-8 lg:mb-0 rounded-lg shadow-lg" />
          <div className="lg:w-2/3 lg:pl-12">
            <p className="text-xl text-gray-700 mb-6">
              NeoBDR combines the power of AI with human expertise, creating a synergy that revolutionizes your sales process. Our AI assistant works tirelessly alongside your team, enhancing productivity and driving results.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {values.map((value, index) => (
            <ValueCard key={index} {...value} />
          ))}
        </div>
        <div className="mt-12 text-center">
          <p className="text-2xl font-semibold text-neo-purple mb-4">Experience the power of AI-driven sales</p>
          <a href="#contact" className="bg-neo-blue text-white px-8 py-4 rounded-full font-semibold hover:bg-neo-purple transition duration-300 inline-block">Get Started with Neo BDR</a>
        </div>
      </div>
    </section>
  );
};

export default ValueProposition;
